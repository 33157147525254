import styled from 'styled-components';
import Header from '../../components/Header';
import { MainContainer, PrimaryLink } from '../../components/common';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, useToast } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { db, downloadThumbnail } from '../../services/firebaseService';
import moment from 'moment';
import { Logout } from '../../store/actions/authActions';
import DownloadButton from './DownloadButton';
import Footer from '../../components/Footer';
import Login from './Login';
import './index.css';
import Order from '../../components/Order';

const Container = styled.div`
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
`;

const PanelLink = styled.a`
    font-weight: ${props => props.active ? 'bold' : 'normal'};
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;

    &:hover {
        color: ${props => props.active ? 'rgba(0, 0, 0, 0.8)' : '#000'}
    }
`;

const getSmoothLevel = (smoothLevel) => {
    switch (smoothLevel) {
        case '16':
            return 'Muito fácil';
        case '8':
            return 'Fácil';
        default:
            return 'Normal'
    }
}



function Profile() {
    const { user } = useSelector(state => state.authState);

    const [arts, setArts] = useState([]);
    const [thumbnails, setThumbnails] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user?.uid) {
            return;
        }
        db.collection('purchase')
            .where('userId', '==', user.uid)
            .onSnapshot((querySnapshot) => {
                const arts = [];
                const urlsPromises = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    const art = {
                        id: doc.id,
                        fileId: data.fileId,
                        createdAtDatetime: data.createdAt.toDate(),
                        createdAt: moment(data.createdAt.toDate()).format('DD/MM/YYYY HH:mm') || 'N/A',
                        palette: `Conjunto de ${data.paletteId} cores`,
                        smoothLevel: getSmoothLevel(data.smoothLevel),
                        status: data.status
                    }
                    arts.push(art);
                    urlsPromises.push(downloadThumbnail(doc.id));
                });
                arts.sort((a, b) => b.createdAtDatetime - a.createdAtDatetime);
                Promise.all(urlsPromises)
                    .then((urls) => {
                        const urlMap = {};
                        urls
                            .filter((data) => data.url)
                            .forEach(({ id, url }) => {
                                urlMap[id] = url;
                            });
                        setThumbnails(urlMap);
                    })
                setArts(arts);
            })
    }, [user])

    function renderLastArtsOrCallToAction(arts) {
        if (arts.length === 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <p style={{ fontSize: '24px', color: '#333333' }}>Meus pedidos</p>
                    <div style={{ height: '1px', backgroundColor: '#333333', flexGrow: 1 }}></div>
                </div>
                <div>
                    <PrimaryLink href="/generate">Começar a criar!</PrimaryLink>
                </div>
            </div>
            )
        }

        return renderLastArts(arts);
    }

    function renderLastArts(arts) {
        const myArts = arts.map((art) => {
            return (
                <Order key={art.id} art={art} thumbnail={thumbnails[art.id]}/>
            )
        })

        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <p style={{ fontSize: '24px', color: '#333333' }}>Meus pedidos</p>
                    <div style={{ height: '1px', backgroundColor: '#333333', flexGrow: 1 }}></div>
                </div>
                {myArts}
            </div>
        )
    }

    return (
        <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            {/* {!user && <Redirect to="/"/>} */}
            <Header />
            <MainContainer className='Container'>
                {user && <div className='Profile'>
                    <div>
                        <Container className='Profile__config'>
                            <PanelLink active={true}>Painel</PanelLink>
                            <PanelLink onClick={() => dispatch(Logout())}>Sair</PanelLink>
                        </Container>
                    </div>
                    <Container className='Profile__panel'>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <p style={{ fontSize: '24px', color: '#333333' }}>Meu painel</p>
                            <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#1976D2' }}>Olá, {user?.displayName}!</p>
                            <p style={{ color: '#333333' }}>No painel de sua conta você poderá ver um resumo de suas atividades recentes.</p>
                        </div>
                        {renderLastArtsOrCallToAction(arts)}
                    </Container>
                </div> }
                {!user && <Login/>}
            </MainContainer>
            <Footer/>
        </div>
    )
}

export default Profile;