import {StepTitle, StepDescription} from '../../common';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ActionBar from '../ActionBar';
import PaletteSelector from '../PaletteSelector';
import { useDispatch, useSelector } from 'react-redux';
import { outlineImage, smoothImage } from '../../../store/actions/wizardActions';
import './index.css';
import { useHistory } from 'react-router-dom';


export default function SmoothImageStep() {

    const dispatch = useDispatch();
    const {fileId, palette, smoothedImage, smoothLevel, availablePalettes} = useSelector(state => state.wizardState);
    const history = useHistory();

    return (
        <div className='SmoothImageStep'>
            <div className='SmoothImageStep__title'>
                <StepTitle>Escolha um conjunto de cores para colorir o desenho</StepTitle>
                <StepDescription>Você pode escolher qual conjunto de cores fica melhor, ou qual conjunto você já tem em sua casa</StepDescription>
            </div>
            <div className='SmoothImageStep__body'>
                <div className='SmoothImageStep__preview'>
                    <StepDescription>Pré-visualização</StepDescription>
                    <img className='SmoothImageStep__preview__image' src={smoothedImage}/>
                </div>
                <div className='SmoothImageStep__config'>
                    <PaletteSelector palettes={availablePalettes} active={palette} />
                    <div className='SmoothImageStep__difficulty'>
                        <StepDescription>Escolha o nível de dificuldade</StepDescription>
                        <div className='SmoothImageStep__difficulty__slider'>
                            <Slider
                                marks={{0: {
                                    style: {
                                        background: 'none'
                                    },
                                    label: 'Normal'
                                }, 1: {
                                    style: {
                                        background: 'none'
                                    },
                                    label: 'Fácil'
                                }, 2: 'Muito fácil'}}
                                min={0}
                                max={2}
                                activeDotStyle={{background: 'none', border: 'none'}}
                                handleStyle={{border: 'none', backgroundColor: '#1976D2'}}
                                trackStyle={{backgroundColor: '#1976D2'}}
                                value={(smoothLevel / 4) - 1}
                                onChange={(value) => {
                                    switch(value) {
                                        case 2:
                                            dispatch(smoothImage(fileId, palette, 16));
                                            break;
                                        case 1:
                                            dispatch(smoothImage(fileId, palette, 8));
                                            break;
                                        default:
                                            dispatch(smoothImage(fileId, palette, 4));
                                            break;
                                    }
                                }}
                                />
                        </div>
                    </div>
                </div>
            </div>
            <ActionBar onNext={() => {
                dispatch({type: 'WIZARD_MUTATE', value: {smoothedId: `${fileId}_${smoothLevel}_${palette.id}`}});
                dispatch(outlineImage(`${fileId}_${smoothLevel}_${palette.id}`, history));
                
            }}/>
        </div>
    );
}