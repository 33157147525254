import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { WIZARD_PREVIOUS_STEP } from '../../../store/actions/actionTypes';
import {SecondaryButton} from '../../common';
import './index.css';


const ActionBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px 30px 60px;
`;

export default function ActionBar({onNext, onPrevious, disablePrevious, disableNext}) {

    const dispatch = useDispatch();
    return (
        <div className="ActionBar">
            <SecondaryButton style={{opacity: disablePrevious ? 0 : 1}} disabled={disablePrevious} onClick={ (() => dispatch({type: WIZARD_PREVIOUS_STEP})) || onPrevious }>Voltar</SecondaryButton>
            <SecondaryButton style={{opacity: disableNext ? 0 : 1}} disabled={disableNext} onClick={onNext}>Próximo</SecondaryButton>
        </div>
    )
}