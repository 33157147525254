import axios from 'axios';
const gateway = 'https://png-functions-hl6qkiudeq-uc.a.run.app';
//const gateway = 'http://localhost:8080';

export const uploadNewFile = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    return axios.post(`${gateway}/upload`, formData, {
        headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    }});
}

export const getSmoothedImage = (id, palette, smoothLevel) => {
    return axios.get(`${gateway}/smooth`, {
        responseType: 'blob',
        params: {
            id,
            palette,
            smoothLevel
        }
    })
}

export const getOutlinedImage = (id) => {
    return axios.get(`${gateway}/outline`, {
        responseType: 'blob',
        params: {
            id
        }
    });
}

export const getSmoothedAndOutlinedImage = async (outlinedId) => {
    const parts = outlinedId.split('_');
    if (parts.length !== 3) {
        throw 'INVALID_FILE_ID';
    }

    const [id, smoothLevel, palette] = parts;
    const promises = [getOutlinedImage(outlinedId), getSmoothedImage(id, palette, smoothLevel)];
    const responses = await Promise.all(promises);
    return responses;

}

export const getPaletteReference = (id) => {
    return axios.get(`${gateway}/palette`, {
        responseType: 'blob',
        params: {
            id
        }
    });
}

export const downloadStandardKit = (id) => {
    return axios.get(`${gateway}/download`, {
        responseType: 'blob',
        params: {
            id
        }});
}

export const downloadHighKit = (purchaseId) => {
    return axios.get(`${gateway}/download`, {
        responseType: 'blob',
        params: {
            purchaseId
        }});
}

export const generatePayment = (id, token) => {
    return axios.get(`${gateway}/payment`, {
        params: {
            id,
            token
        }
    })
}

export default {downloadStandardKit, downloadHighKit, generatePayment};