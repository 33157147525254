
import classNames from 'classnames'
import './index.css';

function BreadcrumbItem({index, text, active}) {
    return (
        <div className='breadcrumb__item'>
            <div className={classNames('breadcrumb__item__index', {'breadcrumb__item__index--active': active})}>{index}</div>
            <div className='breadcrumb__item__text'>{text}</div>
        </div>
    )
};

export default BreadcrumbItem;