import OrderDownloadButton from '../OrderDownloadButton';
import { Spinner } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import './index.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function Order({art, thumbnail}) {

    const {width: windowWidth} = useWindowDimensions();

    const getStatus = (status) => {
        switch (status) {
            case 'approved':
                return (
                    <>
                        <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#00af91' }}>Pago</span>
                        <CheckIcon color="#00af91" />
                    </>
                )
            default:
                return (
                    <>
                        <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'rgba(0,0,0,0.5)' }}>Pendente</span>
                        <Spinner color="rgba(0,0,0,0.5)" size="sm" />
                    </>
                )

        }
    }

    const renderDesktopOrder = () => {
        return (
            <div className='Order'>
                <div className='Order__thumbnail'>
                    <img src={thumbnail} />
                </div>
                <div>
                    <p className='Order__section__title'>Realizado em</p>
                    <p className='Order__section__description'>{art.createdAt}</p>
                </div>
                <div>
                    <p className='Order__section__title'>Paleta</p>
                    <p className='Order__section__description'>{art.palette}</p>
                </div>
                <div>
                    <p className='Order__section__title'>Dificuldade</p>
                    <p className='Order__section__description'>{art.smoothLevel}</p>
                </div>
                <div>
                    <p className='Order__section__title'>Status</p>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                        {getStatus(art.status)}
                    </div>
                </div>
                <OrderDownloadButton id={art.id} deactivated={art.status !== 'approved'}/>
            </div>
        )
    }

    const renderMobileOrder = () => {
        return (
            <div className='Order'>
                <div>
                    <div>
                        <p className='Order__section__title'>Realizado em: <b>{art.createdAt}</b></p>
                    </div>
                    <div>
                        <p className='Order__section__title'>Paleta: <b>{art.palette}</b></p>
                    </div>
                    <div>
                        <p className='Order__section__title'>Dificuldade: <b>{art.smoothLevel}</b></p>
                    </div>
                </div>
                <div style={{width: '80%', height: '1px', backgroundColor: 'rgba(0,0,0,0.1)'}}></div>
                <div className='Order__thumbnail'>
                    <img src={thumbnail} />
                </div>
                <div style={{width: '80%', height: '1px', backgroundColor: 'rgba(0,0,0,0.1)'}}></div>
                <div className='Order__action'>
                    <div className='Order__status'>
                        {getStatus(art.status)}
                    </div>
                    <OrderDownloadButton id={art.id} deactivated={art.status !== 'approved'}/>
                </div>
            </div>
        )
    }

    const renderOrder = () => {
        if (windowWidth <= 1280) {
            return renderMobileOrder();
        }

        return renderDesktopOrder();
    }

    return renderOrder();
}