import firebase, {GoogleLogin} from '../../services/firebaseService';
export const LoginWithGoogle = () => {
    return dispatch => {
        GoogleLogin()
            .then((response) => {
                dispatch({type: 'LOGIN_SUCCEEDED', 
                    token: response.credential.idToken,
                    user: response.user
                })
            })
            .catch((error) => {
                console.error('Error firebase/auth', error);
            })
    }
}

export const Logout = () => {
    return dispatch => {
        firebase.auth().signOut();
        dispatch({type: 'LOGOUT'});
    }
}