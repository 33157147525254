import Breadcrumb from '../Breadcrumb';
import styled from 'styled-components';
import PickImageStep from './PickImageStep';
import { useSelector } from 'react-redux';
import SmoothImageStep from './SmoothImageStep';
import LoadingOverlay from '../LoadingOverlay';
import DownloadStep from './DownloadStep';
import './index.css';

const WizardContainer = styled.div`
    width: 80%;
    height: 800px;
    margin: 10px auto;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export default function CreateWizard() {
    const { step, stepLoading, opaqueStepLoading, stepLoadingText } = useSelector(state => state.wizardState);

    return (
        <>
            <Breadcrumb activeStep={step} />
            <div className='Wizard'>
                {step === 0 &&
                    <PickImageStep />}
                {step === 1 &&
                    <SmoothImageStep />}
                {step === 2 && <DownloadStep/>}
                {stepLoading && <LoadingOverlay isOpaque={opaqueStepLoading} text={stepLoadingText} />}
            </div>
        </>
    )
}