import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ColorWheelGray from '../../../assets/color-wheel-gray.svg';
import ColorWheel from '../../../assets/color-wheel.svg';
import {StepDescription} from '../../common';
import {smoothImage} from '../../../store/actions/wizardActions';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';

export const PaletteItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
    &:hover {
        background-color: ${props => !props.active ? '#f4f4f4' : 'transparent'};
    }
`;

export const PaletteItemIcon = styled.img`
    width: 80px;
`;

export default function PaletteSelector({palettes, active}) {

    const [activePalette, setActivePalette] = useState(active);

    const {fileId, smoothLevel} = useSelector(state => state.wizardState);

    const dispatch = useDispatch();

    useEffect(() => {
        setActivePalette(active);
    }, [active])

    const renderPalettes = (palettes, active) => {
        return palettes.map(palette => (
            <div key={palette?.id} 
                className={`PaletteSelector__item ${active === palette?.id ? 'PaletteSelector__item--active': ''}`} 
                onClick={() => dispatch(smoothImage(fileId, palette, smoothLevel))}>
                <img className="PaletteSelector__item__icon" src={palette?.id === active?.id ? ColorWheel : ColorWheelGray}/>
                <span>{palette.name}</span>
            </div>
        ))
    }

    return (
        <>
            <StepDescription>Escolha um conjunto de lápis de cor</StepDescription>
            <div className='PaletteSelector' onMouseLeave={() => setActivePalette(active)}>
                {renderPalettes(palettes, activePalette)}
            </div>
        </>
    )
}