import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Generate from './views/Generate';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { Store } from './store';
import { Provider, useDispatch } from 'react-redux';
import { ChakraProvider } from "@chakra-ui/react";
import firebase, {LogPageView, db} from './services/firebaseService';
import Profile from './views/Profile';
import { useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import Landing from './views/Landing';

function AuthLoading({ children }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      dispatch({ type: 'LOGIN_SUCCEEDED', user });
      firebase.auth().currentUser.getIdToken(true).then((token) => {
        dispatch({type: 'LOGIN_SUCCEEDED', user, token});
      })
    }
  })
  firebase
    .auth()
    .getRedirectResult()
    .then(() => dispatch({type: 'LOGIN_SUCCEEDED', user: firebase.auth().currentUser}))
    .finally(() => { 
      setLoading(false); 
      db.collection('products')
            .doc('high-quality')
            .onSnapshot((doc) => {
              const data = doc.data();
              dispatch({type: 'UPDATE_PRICE', price: data.price, oldPrice: data.oldPrice})
            });
    })

  return (
    <>
    {loading && <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <div style={{margin: 'auto'}}>
        <Spinner/>
      </div>
      </div>}
    {!loading && children}
    </>
  );
}

function FirebaseAnalytics() {
  let location = useLocation();
  useEffect(() => {
      LogPageView(location);
  }, [location]);
  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <ChakraProvider>
        <AuthLoading>
            <BrowserRouter>
              <FirebaseAnalytics/>
              <Switch>
                <Route path="/profile" component={Profile} />
                <Route path="/generate" component={Generate} />
                <Route path="/" component={Landing} />
              </Switch>
            </BrowserRouter>
        </AuthLoading>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
