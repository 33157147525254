import Header from '../../components/Header';
import {MainContainer, PrimaryButton, PrimaryLink} from '../../components/common';
import styled from 'styled-components';
import PlaceholderStep1 from '../../assets/placeholder_step_1.png';
import PlaceholderStep2 from '../../assets/placeholder_step_2.png';
import PlaceholderStep3 from '../../assets/placeholder_step_3.png';
import Footer from '../../components/Footer';
import SignUpModal from '../../components/SignUpModal';
import './index.css';

const CallToAction = styled(PrimaryLink)`
    padding: 12px 0;
    font-size: 15px;
    font-family: 'Inter';
    font-weight: 600;
    width: 260px;
    text-align: center;
`;

const Step = ({image, step, text, inverted}) => {

    const getImage = (image) => {
        return (
            <img src={image} style={{width: '200px'}}/>
        )
    }

    const getText = (step, text) => {
        return (
            <div>
                <p style={{fontSize: '16px', fontWeight: 'bold', fontFamily: 'Inter'}}>Passo {step}</p>
                <p style={{fontSize: '16px', fontWeight: 'medium', fontFamily: 'Inter'}}>{text}</p>
            </div>
        )
    }

    const renderBody = (image, step, text, inverted) => {
        if (inverted) {
            return (
                <>
                    {getText(step, text)}
                    {getImage(image)}
                </>
            )
        }

        return (
            <>
            {getImage(image)}
            {getText(step, text)}
            </>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '20px'}}>
            {renderBody(image, step, text, inverted)}
        </div>
    )
}

export default function Landing() {
    return (
        <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Header/>
            <MainContainer style={{backgroundColor: 'white', padding: '0', height: '100%'}}>
                <div className='Landing-hero'>
                    <div className='Landing-hero-text'>
                        <p className='Landing-hero-text-title'>Use seus lápis de cor para pintar desenhos incríveis!</p>
                        <p className='Landing-hero-text-subtitle'>Transforme suas fotos em verdadeiras pinturas usando apenas seus lápis de cor!</p>
                        <CallToAction href='/generate'>Começar a criar!</CallToAction>
                    </div>
                    <div className='Landing-hero-steps'>
                        <Step image={PlaceholderStep1} step={1} text={'Escolha uma das suas imagens mais incrível'}/>
                        <Step image={PlaceholderStep2} step={2} inverted text={'Selecione qual conjunto de lápis de cor você tem em casa, seja ele de 72 cores, 36 cores, 24 cores ou 12 cores. Depois escolha o nível de difículdade.'}/>
                        <Step image={PlaceholderStep3} step={3} text={'Pronto! Agora é só imprimir e seguir os números de acordo com as cores indicadas na referência.'}/>
                    </div>
                </div>
            </MainContainer>
            <Footer/>
            <SignUpModal/>
        </div>
    )
}