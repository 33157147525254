const initialState = {
    isModalOpen: false,
    user: null,
    token: null
}


export const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'LOGOUT':
            return {...state, user: null, token: null};
        case 'LOGIN_SUCCEEDED':
            return {...state, user: action.user, token: action.token};
        case 'SIGNUP_MUTATE':
            return {...state, ...action.value};
        default:
            return state;
    }
}