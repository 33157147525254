import { StepTitle, StepDescription} from '../../common';
import ActionBar from '../ActionBar';
import {PaletteItemContainer, PaletteItemIcon} from '../PaletteSelector';
import ColorWheel from '../../../assets/color-wheel.svg';
import Select from 'react-select'
import { useSelector } from 'react-redux';
import DownloadButton from './DownloadButton';
import { useState } from 'react';
import './index.css';


const formatOptionLabel = ({ value, label, price, oldPrice }) => {

    if (price) {
        return (
            <div style={{ display: "flex", gap: '5px' }}>
                <div>
                    {label}
                </div>
                <div>
                    (<span style={{textDecoration: 'line-through'}}>{oldPrice}</span>
                </div>
                <div> -> {price})</div>
                
            </div>
        )
    }

    return (
        <div style={{ display: "flex", gap: '5px' }}>
            <div>{label}</div>
        </div>
    )
};

export default function DownloadStep() {

    const {smoothedImage, outlinedImage, palette, smoothLevel, highQuality} = useSelector(state => state.wizardState);

    const getDifficulty = (smoothLevel) => {
        switch (smoothLevel) {
            case 16:
                return 'Muito fácil'
            case 8:
                return 'Fácil'
            default:
                return 'Normal'
        }
    }

    const [quality, setQuality] = useState('high');

    const getQualities = (highQuality) => {
        return  [
            {
                value: 'Alta (R$ 2,99)',
                label: 'Alta',
                price: `R$ ${highQuality?.price}`,
                oldPrice: `R$ ${highQuality?.oldPrice}`,
                quality: 'high'
            },
            {
                value: 'Pré-visualização (Grátis)',
                label: 'Pré-visualização (Grátis)',
                quality: 'standard'
            }
        ];
    }

    return (
        <div className='DownloadStep'>
            <div className='DownloadStep__body'>
                <div className='DownloadStep__preview'>
                    <img src={smoothedImage} alt="Suavizado"/>
                    <img src={outlinedImage} alt="Referência"/>
                    {/* <img src={referenceImage} style={{ width: '200px', transform: 'rotate(-20deg)', height: '150px', objectFit: 'cover' }} /> */}
                </div>
                <div className="DownloadStep__form">
                    <StepTitle style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '20px' }}>Baixe seu kit pronto para começar!</StepTitle>
                    <StepDescription>Paleta de cores</StepDescription>
                    <PaletteItemContainer active={true}>
                        <PaletteItemIcon src={ColorWheel} />
                        <span>{palette.name}</span>
                    </PaletteItemContainer>
                    <div style={{ width: '100%', height: '1px', backgroundColor: '#c4c4c4' }} />
                    <div>
                        <StepDescription>Nível de dificuldade</StepDescription>
                        <StepTitle>{getDifficulty(smoothLevel)}</StepTitle>
                    </div>
                    <div style={{ width: '100%', height: '1px', backgroundColor: '#c4c4c4' }} />
                    <div>
                        <StepDescription style={{ marginBottom: '5px' }}>Qualidade</StepDescription>
                        <Select
                            defaultValue={getQualities(highQuality)[0]}
                            placeholder={'Selecione a qualidade'}
                            onChange={({quality}) => {
                                setQuality(quality);
                            }}
                            formatOptionLabel={formatOptionLabel}
                            options={getQualities(highQuality)} />
                    </div>
                    <p style={{fontSize: '12px', textAlign: 'left'}}>Você pode pagar com Pix!</p>
                    <DownloadButton quality={quality}/>
                </div>
            </div>
            <ActionBar disableNext={true}/>
        </div>
    )
}