import { Spinner } from "@chakra-ui/react";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../../common"
import { useToast } from '@chakra-ui/toast';
import imageService from '../../../../services/imageProcessing';
import { LogHighDownload, LogStandardDownload } from '../../../../services/firebaseService';
import { GoogleLogin } from "../../../../services/firebaseService";
import firebase from '../../../../services/firebaseService';
import './index.css';
import pixelService from '../../../../services/pixelService';

export default function DownloadButton({quality}) {

    const toast = useToast();
    const {smoothedId} = useSelector(state => state.wizardState);

    const {user} = useSelector(state => state.authState);

    const dispatch = useDispatch();

    const downloadKit = (outlinedId, smoothedId, user) => {
        if (loading) {
            return;
        }

        setLoading(true);
        if (quality === 'high') {
            pixelService.LogPixelInitiateCheckout();
            if (!user) {
                return GoogleLogin()
                .then((response) => {
                    console.log(response);
                    dispatch({type: 'LOGIN_SUCCEEDED', 
                        token: response.credential.idToken,
                        user: response.user
                    });
                    return getIdTokenAndDownloadHighKit(smoothedId);
                    
                })
                .catch((error) => {
                    console.error('Error firebase/auth', error);
                    toast({
                        title: 'Ops! Você precisa entrar ou se cadastrar...',
                        description: 'Por favor, clique em baixar novamente',
                        status: 'warning',
                        isClosable: true
                    });
                    setLoading(false);
                })
            }
            return getIdTokenAndDownloadHighKit(smoothedId);
        }
        return downloadStandardKit(outlinedId);
    }

    const getIdTokenAndDownloadHighKit = (smoothedId) => {
        return firebase.auth().currentUser.getIdToken(true)
            .then((token) => {
                return downloadHighKit(smoothedId, token);
            })
    }

    const downloadHighKit = (id, token) => {
        LogHighDownload(id);
        pixelService.LogPixelPurchase();
        imageService.generatePayment(id, token)
            .then((response) => {
                window.location.href = response.data.initPoint;
                // window.open(response.data.initPoint);
                // history.push('/profile');
            })
            .catch((error) => {
                console.error(error);
                toast({
                    title: 'Ops! Tivemos algum problema gerando seu pedido...',
                    description: 'Por favor, tente novamente',
                    status: 'error',
                    isClosable: true
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const downloadStandardKit = (id) => {
        LogStandardDownload(id);
        imageService.downloadStandardKit(id)
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data)
                link.download = 'kit.zip'
                link.click();
            })
            .catch((error) => {
                console.error(error);
                toast({
                    title: 'Ops! Não foi possível gerar seu kit...',
                    description: 'Por favor, tente novamente',
                    status: 'error',
                    isClosable: true
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const [loading, setLoading] = useState();
    const {outlinedId} = useSelector(state => state.wizardState);

    return (
        <>
        {loading && <PrimaryButton className="DownloadButton"><Spinner/></PrimaryButton>}
        {!loading && <PrimaryButton className="DownloadButton" onClick={() => downloadKit(outlinedId, smoothedId, user)}>Baixar kit</PrimaryButton>}
        </>
    )
}