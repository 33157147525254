import { createStore, compose, applyMiddleware } from 'redux';
import { Reducers } from './reducers';
import { reactReduxFirebase } from "react-redux-firebase";
import firebase from "../services/firebaseService";
import reduxThunk from "redux-thunk";

const createStoreWithFirebase = compose(reactReduxFirebase(firebase))(
    createStore
);

export const Store = createStoreWithFirebase(Reducers, {}, applyMiddleware(reduxThunk));
