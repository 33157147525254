import styled from 'styled-components';

export const LinkButton = styled.a`
    text-decoration: none;
    color: #646369;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    margin: 5px;
    &:hover {
        color:#000;
    }
`;

export const PrimaryLink = styled.a`
    background-color: #FF9800;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px 12px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin: 5px;
    &:hover {
        background-color: #f0ab00;
    }
`;

export const PrimaryButton = styled.button`
    background-color: #FF9800;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px 12px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin: 5px;
    &:hover {
        background-color: #f0ab00;
    }
`;


export const MainContainer = styled.div`
    display: block;
    padding: 20px;
    align-items: center;
    justify-content: center;
    background-color: #F6F6F6;
`;


export const StepTitle = styled.p`
    margin: 5px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000;
`;

export const StepDescription = styled.p`
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000;
`;

export const SecondaryButton = styled.button`
    background-color: #1976D2;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 100px;
    font-size: 14px;
    padding: 10px 20px;
    font-family: 'Inter', sans-serif;
    margin: 5px;
    &:hover {
        background-color: #303F9F;
    }
`;
