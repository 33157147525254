import Header from '../../components/Header';
import CreateWizard from '../../components/CreateWizard';
import SignUpModal from '../../components/SignUpModal';
import Footer from '../../components/Footer';
import './index.css';
import {useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getOutlinedImage, getSmoothedImage, getSmoothedAndOutlinedImage} from '../../services/imageProcessing';
import { useEffect } from 'react';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Generate() {
    const dispatch = useDispatch();
    const query = useQuery();
    const id = query.get('id');
    const {outlinedId} = useSelector(state => state.wizardState);
    
    useEffect(() => {
        if (id && id !== outlinedId) {
            dispatch({type: 'WIZARD_MUTATE', value: {stepLoading: true, opaqueStepLoading: true}});
            getSmoothedAndOutlinedImage(id)
                .then((responses) => {
                    const outlinedImage = URL.createObjectURL(responses[0].data);
                    const smoothedImage = URL.createObjectURL(responses[1].data);
                    dispatch({type: 'WIZARD_LOAD_FROM_ID', value: {
                        smoothedImage,
                        outlinedImage,
                        outlinedId: id
                    }});
                })
                .catch(() => {
                    dispatch({type: 'WIZARD_MUTATE', value: {
                        stepLoading: false
                    }})
                })
        }
        
    }, [id]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Header/>
            <div className='Generate'>
                <CreateWizard/>
                <SignUpModal/>
            </div>
            <Footer/>
        </div>
    )
}

export default Generate;