import styled from 'styled-components';

import { Spinner } from '@chakra-ui/react';

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.isOpaque ? '#fff' : 'rgba(150, 150, 150, 0.50)'};
`;

export default function LoadingOverlay({isOpaque, text}) {
    return (
        <Container isOpaque={isOpaque}>
                <Spinner size="xl" />
                {isOpaque && <p>{text || 'Carregando'}</p>}
        </Container>
    )
}