import Logo from '../Logo';
import './index.css'

export default function Footer() {
    return (
        <div className="Footer">
            <div className="Footer__logo">
                <Logo style={{fontSize: '18px'}}/>
                <span>2021 © Todos os direitos reservados</span>
            </div>
            <a href="mailto:contato.mapacores@gmail.com">contato.mapacores@gmail.com</a>
        </div>
    )
}