import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {LinkButton} from '../common';
import './index.css';

const UserContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    cursor: pointer;
`;

function HeaderUser() {
    const {user} = useSelector(state => state.authState);
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <>
            {!user && <UserContainer>
                <LinkButton onClick={() => dispatch({type: 'SIGNUP_MUTATE', value: {isModalOpen: true}})}>Entrar</LinkButton>
            </UserContainer>}
            {user && 
            <div className="user-photo-container" onClick={() => history.push('/profile')}>
                <img className="user-photo" src={user.photoURL} alt={user.email}/>
                <span className="my-orders">Meus pedidos</span>
            </div>}
        </>
    )
};

export default HeaderUser;