import {useToast} from '@chakra-ui/toast';
import {getOutlinedImage, getPaletteReference, getSmoothedImage} from '../../services/imageProcessing';
import { WIZARD_SET_SMOOTHED_IMAGE } from './actionTypes';
export const smoothImage = (fileId, palette, smoothLevel) => {
    return dispatch => {
        if (!fileId) {
            return;
        }
        dispatch({type: 'WIZARD_MUTATE', value: {stepLoading: true, opaqueStepLoading: false}});
        getSmoothedImage(fileId, palette.id, smoothLevel)
            .then((response) => {
                dispatch({type: WIZARD_SET_SMOOTHED_IMAGE, value: {
                    smoothedImage: URL.createObjectURL(response.data),
                    fileId,
                    palette,
                    smoothLevel,
                    stepLoading: false
                }});
            })
            .catch((error) => {
                console.error(error);
                dispatch({type: 'WIZARD_MUTATE', value: {stepLoading: false, opaqueStepLoading: false}});
            })
    }
}

export const outlineImage = (fileId, history) => {
    return dispatch => {
        if (!fileId) {
            return;
        }
        dispatch({type: 'WIZARD_MUTATE', value: {stepLoading: true, opaqueStepLoading: true, stepLoadingText: 'Carregando referência numérica...'}});
        getOutlinedImage(fileId)
            .then((response) => {
                const outlinedImage = URL.createObjectURL(response.data);
                dispatch({type: 'WIZARD_MUTATE', value: {
                    outlinedImage,
                    outlinedId: fileId,
                    stepLoading: false
                }});
                dispatch({type: 'WIZARD_NEXT_STEP'});
                history.push({
                    pathname: '/generate',
                    search: `?id=${fileId}`
                });

                // getPaletteReference(fileId)
                //     .then((response) => {
                        // dispatch({type: 'WIZARD_MUTATE', value: {
                        //     outlinedImage,
                        //     outlinedId: fileId,
                        //     referenceImage: URL.createObjectURL(response.data),
                        //     stepLoading: false
                        // }});
                        // dispatch({type: 'WIZARD_NEXT_STEP'});
                //     })
                //     .catch((error) => {
                //         console.error(error);
                //     })
                //     .finally(() => {
                //         dispatch({type: 'WIZARD_MUTATE', value: {stepLoading: false, opaqueStepLoading: false}});
                //     })
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch({type: 'WIZARD_MUTATE', value: {stepLoading: false, opaqueStepLoading: false}});
            });
    }
}