import { useState } from "react"
import { Spinner, useToast } from '@chakra-ui/react';
import { PrimaryButton } from '../../components/common';
import imageService from '../../services/imageProcessing';
import styled from 'styled-components';

const DeactivateLinkButton = styled(PrimaryButton)`
background-color: gray;
&:hover {
    background-color: gray;
}
`;

export default function DownloadButton({id, deactivated}) {
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const downloadHighKit = (purchaseId) => {
        if (loading) {
            return;
        }

        setLoading(true);
        imageService.downloadHighKit(purchaseId)
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data)
                link.download = 'kit.zip'
                link.click();
            })
            .catch((error) => {
                console.error(error);
                toast({
                    title: 'Ops! Não foi possível gerar seu kit...',
                    description: 'Por favor, tente novamente',
                    status: 'error',
                    isClosable: true
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const renderButton = (id) => {
        if (loading) {
            return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner/>
            </div>)
        }

        return (
            <PrimaryButton onClick={() => {downloadHighKit(id)}}><span>Baixar</span></PrimaryButton>
        );
    }

    return (
        <>
        {deactivated && <DeactivateLinkButton disabled><span>Baixar</span></DeactivateLinkButton>}
        {!deactivated && renderButton(id)}
        </>
    )
}