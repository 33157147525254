import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux"
import GoogleSignUpButton from "./GoogleSignUpButton";
import {SmallCloseIcon} from '@chakra-ui/icons';
import Logo from '../Logo';


const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${props => props.isOpen ? 'block': 'none'}
`;

const Container = styled.div`
  position: fixed;
  background-color: white;
  top: 50%;
  left: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 20px;
`;

const Header = styled.h1`
  font-weight: bold;
  text-align: center;
`;

export default function SignUpModal() {
    const {isModalOpen} = useSelector(state => state.authState);
    const dispatch = useDispatch();
    const onOpen = () => {
        dispatch({type: 'SIGNUP_MUTATE', value: {isModalOpen: true}});
    }

    const onClose = () => {
        dispatch({type: 'SIGNUP_MUTATE', value: {isModalOpen: false}});
    }
  return (
    <Overlay isOpen={isModalOpen} onClick={onClose}>
      <Container>
        <div style={{textAlign: 'center'}}>
        <Logo/>
        </div>
        <div style={{position: 'absolute', right: '20px', top: '20px' }}>
          <button style={{outline: 'none', border: 'none'}}><SmallCloseIcon/></button>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '50px'}}>
          <GoogleSignUpButton/>
        </div>

      </Container>
    </Overlay>
  )
}