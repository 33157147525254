import {WIZARD_NEXT_STEP, WIZARD_PREVIOUS_STEP, WIZARD_SET_FILE_ID, WIZARD_SET_SMOOTHED_IMAGE, WIZARD_SET_STEP} from '../actions/actionTypes';


const initialState = {
    step: 0,
    stepLoading: false,
    opaqueStepLoading: false,
    highQuality: {
        price: 5.99,
        oldPrice: 9.99
    },
    availablePalettes: [
        {
            id: '72',
            name: 'Conjunto de 72 cores'
        },
        {
            id: '36',
            name: 'Conjunto de 36 cores'
        },
        {
            id: '24',
            name: 'Conjunto de 24 cores'
        },
        {
            id: '12',
            name: 'Conjunto de 12 cores'
        }
    ],
};

const getPropertiesFromFileId = (fileId) => {
    const parts = fileId.split('_');
    if (parts.length === 1) {
        return {originalId: fileId};
    }

    if (parts.length === 2) {
        return {originalId: parts[0], smoothLevel: parts[1]};
    }

    return {originalId: parts[0], smoothLevel: parseInt(parts[1]), paletteId: parts[2]};
}

const loadFromOutlinedId = ({outlinedImage, outlinedId, smoothedImage}, state) => {
    const {originalId, smoothLevel, paletteId} = getPropertiesFromFileId(outlinedId);

    return {
        ...state,
        outlinedImage,
        fileId: originalId,
        outlinedId,
        smoothedId: outlinedId,
        smoothedImage,
        smoothLevel,
        palette: state.availablePalettes.find(x => x.id === paletteId),
        step: 2,
        stepLoading: false
    }
}


export const wizardReducer = (state = initialState, action) => {
    switch (action.type) {
        case WIZARD_PREVIOUS_STEP:
            return {...state, step: Math.max(state.step - 1, 0)};
        case WIZARD_NEXT_STEP:
            return {...state, step: Math.min(state.step  + 1, 2)};
        case WIZARD_SET_STEP:
            return {...state, step: action.value};
        case WIZARD_SET_FILE_ID:
            return {...state, fileId: action.value};
        case WIZARD_SET_SMOOTHED_IMAGE:
            return {...state, ...action.value};
        case 'WIZARD_LOAD_FROM_ID':
            return loadFromOutlinedId(action.value, state);
        case 'WIZARD_MUTATE':
            return {...state, ...action.value};
        case 'LOGOUT':
            return {...state, step: 0};
        case 'UPDATE_PRICE':
            return {...state, highQuality: {...state.highQuality, price: action.price, oldPrice: action.oldPrice}}
        default:
            return state;
    }
}