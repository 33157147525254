import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import GoogleLightIcon from '../../assets/google_icon.svg';
import { LoginWithGoogle } from '../../store/actions/authActions';

const Container = styled.button`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 24px 8px 8px;
    gap: 24px;
    border-radius: 2px;
    backgroud-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24);
    background-color: white;
    &:hover {
        background-color: #eeeeee;
    }
`;

export default function GoogleSignUpButton() {
    const dispatch = useDispatch();
    return (
        <Container onClick={() => {dispatch(LoginWithGoogle())}}>
            <img src={GoogleLightIcon}/>
            <span style={{fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', color: 'rgba(0,0,0, 0.54)'}}>Continue com o Google</span>
        </Container>
    )
}