import BreadcrumbItem from './BreadcrumbItem';
import ArrowIcon from '../../assets/right-arrow.svg';
import './index.css';

function Breadcrumb({activeStep}) {
    return (
        <div className='breadcrumb'>
            <BreadcrumbItem
                index="1"
                text="Selecione uma imagem"
                active={activeStep === 0}
            />
            <img className="breadcrumb__arrow" src={ArrowIcon} alt='Arrow'/> 
            <BreadcrumbItem
                index="2"
                text="Ajuste as cores"
                active={activeStep === 1}
            />
            <img className="breadcrumb__arrow" src={ArrowIcon} alt='Arrow'/>
            <BreadcrumbItem
                index="3"
                text="Baixe seu desenho"
                active={activeStep === 2}
            />
        </div>
    )
};

export default Breadcrumb;