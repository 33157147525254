import styled from 'styled-components';
import {PrimaryLink} from '../common';
import HeaderUser from '../HeaderUser';
import {useHistory} from 'react-router-dom';
import './index.css';


const Logo = styled.a`
    font-weight: bold;
    font-size: 26px;
    color: #1976D2;
    margin: 0;
    letter-spacing: -1px;
    font-family: 'Varela Round', sans-serif;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
`;

const Divider = styled.span`
    display: block;
    height: 30px;
    width: 1px;
    margin-left: 7px;
    margin-right: 7px;
    position: relative;
    top: 25%;
    background: #e3e3e3;
`;

const HeaderActions = styled.div`
    display: flex;
    align-items: center;
`;

function Header() {
    const history = useHistory();
    return (
        <div className='Header'>
            <Logo href="/">
                mapacores
            </Logo>
            <HeaderActions>
                <PrimaryLink href="/generate">Criar</PrimaryLink>
                <Divider/>
                <HeaderUser/>
            </HeaderActions>
        </div>
    )
}

export default Header;