
const LogPixelInitiateCheckout = () => {
    try {
        window.fbq('track', 'InitiateCheckout');
    } catch (error) {
        console.log('Error pixel')
    }
}

const LogPixelPurchase = () => {
    try {
        window.fbq('track', 'Purchase', {value: 2.99, currency: 'BRL'});
    } catch (error) {
        console.log('Error pixel')
    }
}   

const pixelService = {LogPixelInitiateCheckout, LogPixelPurchase};
export default pixelService;