import { useState } from 'react';
import styled from 'styled-components';
import {StepTitle, StepDescription} from '../../common';
import CloseIcon from '../../../assets/times-circle.svg';
import LoadingAnimation from '../../../assets/loading.json';
import Lottie from 'react-lottie';
import ActionBar from '../ActionBar';
import { useDispatch, useSelector } from 'react-redux';
import { uploadNewFile } from '../../../services/imageProcessing';
import { useToast } from '@chakra-ui/toast';
import { smoothImage } from '../../../store/actions/wizardActions';
import Gallery from '../../Gallery';
import './index.css';


const ChooseImageInput = styled.label`
    background-color: #FF9800;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px 12px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin: 5px;
    &:hover {
        background-color: #f0ab00;
    }
`;

export default function PickImageStep({onNext}) {

    const [currentImageURL, setCurrentImageURL] = useState();
    const [currentFile, setCurrentFile] = useState();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const toast = useToast();

    const {availablePalettes} = useSelector(state => state.wizardState);

    const uploadFile = async () => {
        if (!currentImageURL) {
            return;
        }
        const defaultPalette = availablePalettes.filter(palette => palette.id === '72')[0];
        dispatch({type: 'WIZARD_MUTATE', value: {opaqueStepLoading: true, stepLoading: true, stepLoadingText: 'Carregando referência colorida...'}});
        uploadNewFile(currentFile)
            .then((response) => {
                dispatch({type: 'WIZARD_MUTATE', value: response.data.id});
                dispatch(smoothImage(response.data.id, defaultPalette, 4));
                dispatch({type: 'WIZARD_NEXT_STEP'});
            })
            .catch((error) => {
                console.log('Error', error);
                dispatch({type: 'WIZARD_MUTATE', value: {opaqueStepLoading: false, stepLoading: false}});
                if (!error.response) {
                    return;
                }
                switch (error.response.data.error) {
                    case 'INVALID_IMAGE_TYPE':
                        return toast({
                            title: 'Ops! Isso é uma imagem?',
                            description: 'Houve algum problema com o tipo da imagem :S',
                            status: 'error',
                            isClosable: true
                        });
                    default:
                        return toast({
                            title: 'Ops! Houve algum problema',
                            description: 'Tivemos algum problema ao processar... Poderia tentar novamente?',
                            status: 'error',
                            isClosable: true
                        });
                }
            })

    }

    const onChangeInput = (event) => {
        const imageFile = event.target.files[0];
        if (!imageFile) {
            return;
        }

        if (imageFile.size > 5 * 1024 * 1024) {
            toast({
                title: 'Ops! Arquivo muito grande...',
                description: 'Por favor, use apenas arquivos com no máximo 5MB',
                status: 'error',
                isClosable: true
            })
            return;
        }
        setCurrentImageURL(URL.createObjectURL(event.target.files[0])); 
        setCurrentFile(event.target.files[0])
    }

    return (
        <div className="PickImageStep">
            <div className="PickImageStep__title">
                <StepTitle>Escolha uma imagem que deseja colorir</StepTitle>
                <StepDescription>Tome cuidado com rostos muito longe, rostos desfocados, imagens muito claras ou imagens muito escuras.</StepDescription>
            </div>
            { !currentImageURL && 
            <div className="PickImageStep__pick-image ">
                <div>
                    <input onChange={onChangeInput} accept="image/*" type='file' id='upload' hidden/>
                    <ChooseImageInput htmlFor='upload'>Escolha um arquivo de imagem</ChooseImageInput>
                </div>
                {/* <div style={{fontSize: '18px', fontFamily: 'Inter', padding: '10px'}}>
                    ou
                </div>
                <Gallery/> */}
            </div>} 
            { currentImageURL && 
                <div className='PickImageStep__preview'>
                        <div className='PickImageStep__preview__container'>
                            <img className='PickImageStep__preview__image' src={currentImageURL}/>
                            <img style={{width: '20px', position: 'absolute', top: -10, right: -5, cursor: 'pointer'}} src={CloseIcon} onClick={() => setCurrentImageURL(null)}/>
                        </div>
                    </div>}
            <ActionBar disablePrevious={true} onNext={() => uploadFile()}/>
        </div>
    )
}