import styled from 'styled-components';

const Container = styled.p`
    font-weight: bold;
    font-size: 26px;
    color: #1976D2;
    margin: 0;
    letter-spacing: -1px;
    font-family: 'Varela Round', sans-serif;
`;

export default function Logo({style}) {
    return (
        <Container style={style}>
            mapacores
        </Container>
    )
}