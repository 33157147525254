import GoogleSignUpButton from '../../components/SignUpModal/GoogleSignUpButton';
import styled from 'styled-components';

const Card = styled.div`
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 400px;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
`;
export default function Login() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
        <Card>
            <p style={{fontSize: '24px', fontFamily: 'Inter', fontWeight: 'bold'}}>Faça seu login</p>
            <GoogleSignUpButton/>
        </Card>
        </div>
    )
}