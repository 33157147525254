import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";
import isMobile from 'ismobilejs';

const config = {
    apiKey: "AIzaSyAqbmrtDlnhG9C84DzxrpvgNx0XNZKHcyU",
    authDomain: "paint-by-numbers-generator.firebaseapp.com",
    projectId: "paint-by-numbers-generator",
    storageBucket: "paint-by-numbers-generator.appspot.com",
    messagingSenderId: "1093926058179",
    appId: "1:1093926058179:web:7e6ad8507240520efeca76",
    measurementId: "G-1P942NC696"
};

firebase.initializeApp(config);
export default firebase;

export const storage = firebase.storage();

const analytics = firebase.analytics();

export const downloadThumbnail = async (purchaseId) => {
    try {
        const url = await storage.ref().child(purchaseId).getDownloadURL();
        return {id: purchaseId, url};
    } catch (error) {
        console.log(`Error downloading ${purchaseId}`, purchaseId);
        return {id: purchaseId, error: error};
    }
}

export const GoogleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = 'pt';
    if (isMobile().any) {
        return firebase.auth().signInWithRedirect(provider);
    }

    return firebase.auth().signInWithPopup(provider);
}

export const db = firebase.firestore();

export const LogStandardDownload = (fileId) => {
    analytics.logEvent('standard_download', {fileId});
}

export const LogHighDownload = (fileId) => {
    analytics.logEvent('high_download', {fileId});
}

export const LogPageView = (location) => {
    try {
        if (!analytics) {
            return;
        }
        const page_path = location.pathname + location.search;
        analytics.setCurrentScreen(page_path);
        analytics.logEvent("page_view", { page_path });
    } catch (error) {
        console.log('Error tracking page', error);
    }
}